@mixin min-max($min-width, $max-width, $padding) {
  min-width: $min-width;
  max-width: $max-width;
  padding: 0 $padding;
  box-sizing: border-box;
  margin: 0 auto;
}

@mixin placeholder ($plcolor) {
	::-webkit-input-placeholder {color: $plcolor;}
	::-moz-placeholder          {color: $plcolor; opacity: 1;}
	:-moz-placeholder           {color: $plcolor; opacity: 1;}
	:-ms-input-placeholder      {color: $plcolor;}
}

@mixin hr ($hrcolor) {
	background: $hrcolor;
	border: none;
	color: $hrcolor;
	height: 1px;
}