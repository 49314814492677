@media (max-width: 1340px) {
	.sect__3::after {
		right: -170px;
	}
}

@media (max-width: 1070px) {
	.single__page {
		
		.header {
			
			.center {
				padding-left: 15px;
				padding-right: 15px;
			}
			
		}
		.center {
			width: 100%;
		}
	}
}

@media (max-width: 970px) {
	.center {
		padding-left: 15px;
		padding-right: 15px;
		width: 800px;
	}
	.sect__3::after {
		display: none;
	}
	.toggle-mnu {
		display: block;
	}
	
	.avtopark {
		display: block;
		
		li {
			margin-left: auto;
			margin-right: auto;
		}
	}
	
	.advantages__list li {
		margin-bottom: 10px;
		max-height: 240px;
		overflow: hidden;
		width: 45%;
		
		img {
			object-fit: cover;
		}
	}
	
	.menu {
		background: url(../img/sect_1__bg.jpg) no-repeat center top;
		background-size: cover;
		bottom: 0;
		-ms-align-items: center;
		align-items: center;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		flex-direction: column;
		justify-content: center;
		left: 0;
		position: fixed;
		top: 0;
		transition: transform .3s ease;
		transform: translateX(-100%);
		width: 70%;
		z-index: 90;
		
		li + li {
			margin-left: 0;
		}
		
		a {
			color: #fff;
			font-size: 20px;
			padding: 10px 0;
		}
		
		.special {
			height: 30px;
			font-size: 14px;
			line-height: 10px;
			width: 30px;
		}
	}
	
	.menu__active {
		-webkit-transform: translateX(0);
		-ms-transform: translateX(0);
		-o-transform: translateX(0);
		transform: translateX(0);
	}
}

@media (max-width: 830px) {
	.center {
		width: 700px;
	}
	.slick-prev {
		left: -35px;
	}
	.slick-next {
		right: -35px;
	}
	.delivery__page {
		background: #fff url(../img/delivery-bg.png) no-repeat right -400px top;
	}
}

@media (max-width: 785px) {
	.popup {
		padding-right: 0;
		width: 95%;
		
		.h3 {
			max-width: 320px;
		}
	}
}

@media (max-width: 730px) {
	.center {
		width: 600px;
	}
	.sect__2, .sect__4 {
		padding-bottom: 50px;
		padding-top: 50px;
	}
	.statistic {
		flex-wrap: wrap;
		margin-top: 100px;
	}
	.myslider {
		width: 100%;
	}
	.slick-prev {
		left: -5px;
	}
	.slick-next {
		right: -5px;
	}
}

@media (max-width: 680px) {
	.tabs a {
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media (max-width: 630px) {
	h1, .h1, h2, .h2 {
		font-size: 50px;
	}
	.center {
		width: 500px;
	}
	.phone {
		font-size: 27px;
	}
	.footer {
		.phone {
			font-size: 22px;
		}
		.order__btn {
			font-size: 15px;
		}
		.contacts {
			padding: 20px 0;
			text-align: center;
			width: 100%;
		}
	}
	.table th {
		font-size: 17px; }
	.table th:nth-child(1), .table td:nth-child(1) {
		width: 125px; }
	.table .blue__btn {
		width: 150px;
	}
	.delivery__mixer {
		display: block;
		float: none;
	}
	.deliver__footer {
		display: block;
		
		.span1 {
			display: block;
			margin: 0 0 20px 0;
			text-align: center;
		}
		
		.red__btn {
			margin-left: auto;
		}
	}
	.price {
		background-position: left top;
	}
}

@media (max-width: 530px) {
	.center {
		width: 100%;
	}
	.avtopark li {
		width: 100%;
	}
	.delivery__page {
		background-position: right -500px top;
	}
}

@media (max-width: 500px) {
	h3, .h3 {
		font-size: 30px;
	}
	.advantages__list {
		display: block;
		
		li {
			margin-left: auto;
			margin-right: auto;
			max-height: 100%;
			max-width: 320px;
			width: 100%;
		}
	}
	.popup {
		padding-bottom: 20px;
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 70px;
		
		input, .red__btn, .priv, .h3 {
			margin-left: auto;
			margin-right: auto;
		}
	}
	.popup__form {
		margin-left: 0;
	}
	
	#orderbeton {

		.h3 {
			margin-left: 0;
			max-width: 100%;
		}
	}
}

@media (max-width: 465px) {
	.phone {
		font-size: 24px;
	}
	.statistic li {
		width: 100%;
	}
	.popup {

		input, .red__btn {
			width: 100%;
		}
	}
	.tabs {
		display: block;
		
		li {
			margin-left: 0;
		}
	}
}

@media (max-width: 420px) {
	h1, .h1, h2, .h2 {
		font-size: 44px;
	}
	.table th {
		font-size: 14px; }
	.table th:nth-child(1), .table td:nth-child(1), .table th:nth-child(2), .table td:nth-child(2) {
		padding: 0 11px;
		width: auto; }
	.table .blue__btn {
		width: 60px; }
	.table .blue__btn span {
		display: none; }
}

@media (max-width: 375px) {
	.header .contacts {
		display: none;
	}
	.myform input, .myform .red__btn, .myform {
		width: 100%;
	}
	.avtopark__info--name {
		font-size: 12px;
	}
	.delivery__page {
		background-position: right -550px top;
	}
	.avtopark .wrap__img {
		min-height: auto;
	}
	.avtopark li + li {
		margin-top: 20px;
	}
	.footer__download {
		line-height: 1.2;
		text-align: center;
		width: 60%;
	}
}