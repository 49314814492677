@charset "UTF-8";
/*========================== reset.css ==========================*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, time {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a, input, select, textarea {
  outline: none; }

img, input {
  border: none;
  height: auto;
  max-width: 100%; }

button {
  border: 0;
  cursor: pointer;
  outline: none;
  padding: 0; }

input[type=submit], button[type=submit] {
  cursor: pointer; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

@font-face {
  font-family: 'bebas_neuebold';
  src: url("../fonts/BebasNeueBold/bebasneue_bold-webfont.eot");
  src: url("../fonts/BebasNeueBold/bebasneue_bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/BebasNeueBold/bebasneue_bold-webfont.woff2") format("woff2"), url("../fonts/BebasNeueBold/bebasneue_bold-webfont.woff") format("woff"), url("../fonts/BebasNeueBold/bebasneue_bold-webfont.ttf") format("truetype"), url("../fonts/BebasNeueBold/bebasneue_bold-webfont.svg#bebas_neuebold") format("svg");
  font-weight: normal;
  font-style: normal; }

/**
 * Основные стили
 **/
body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 700;
  overflow-x: hidden; }

strong {
  font-weight: 700; }

a {
  color: #4f6899;
  text-decoration: none;
  transition: color .3s ease; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: 'bebas_neuebold';
  font-weight: normal;
  text-transform: uppercase; }

h1, .h1, h2, .h2 {
  font-size: 60px; }

h2, .h2 {
  color: #242c3a;
  letter-spacing: -1.8px; }
  h2 span, .h2 span {
    color: #5078a9; }

h3, .h3 {
  font-size: 40px; }

.h__line {
  display: table;
  position: relative; }
  .h__line ::after {
    background: #aebdda;
    content: '';
    display: block;
    height: 1px;
    margin: 10px 0 0 0; }

.left {
  float: left; }

.right {
  float: right; }

section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top; }

input[type=text], input[type=email] {
  background: #eff4fe;
  color: #30436e;
  display: block;
  font: 400 16px Roboto;
  height: 50px;
  letter-spacing: -0.48px;
  text-align: center;
  width: 320px; }

.red__btn {
  background-image: linear-gradient(to top, #890b0b 0%, #d20f0f 100%);
  color: #fff;
  display: block;
  font-size: 16px;
  height: 50px;
  letter-spacing: -0.48px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 50px 0 0;
  position: relative;
  width: 264px;
  z-index: 2; }
  .red__btn span {
    background: #690101 url(../img/car-icon.png) no-repeat center;
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px; }
  .red__btn:hover {
    background-image: linear-gradient(to bottom, #890b0b 0%, #d20f0f 100%); }
  .red__btn--beton span {
    background-image: url(../img/betoncar-icon.png); }

.wrapper {
  margin: 0 auto;
  max-width: 2560px;
  overflow: hidden; }

.center {
  margin: 0 auto;
  width: 940px; }

.sect__1 {
  background-image: url(../img/sect_1__bg.jpg);
  padding: 249px 0 30px 0;
  position: relative; }

.header {
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 5; }
  .header.fixed {
    background: url(../img/sect_1__bg.jpg) no-repeat top center; }
  .header .center {
    align-items: center;
    display: flex;
    justify-content: space-between; }
  .header a {
    color: #fff; }

.logo {
  background: #d70808 url(../img/car-icon.png) no-repeat right 8px top 10px;
  color: #fff;
  display: block;
  font-size: 24px;
  padding: 10px 0 0 0;
  width: 107px; }
  .logo .logo__row1 {
    display: block;
    padding: 0 0 0 9px; }
  .logo .logo__row2 {
    background: #bd0707;
    color: #ffcfcf;
    display: block;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.36px;
    margin: 5px 0 0 0;
    padding: 5px 5px 5px 8px; }

.menu {
  display: flex; }
  .menu li {
    letter-spacing: -0.42px; }
    .menu li + li {
      margin-left: 35px; }
  .menu a {
    color: #fff;
    display: block; }
    .menu a:hover {
      color: #aebdda; }

.contacts {
  text-align: right; }
  .contacts .order__btn {
    display: inline-block;
    margin: 2px 0 0 0;
    text-decoration: underline; }

.phone {
  color: #fff;
  display: block;
  font-size: 30px;
  letter-spacing: -0.9px; }
  .phone span {
    color: #aebdda; }

.title__1 {
  color: #fff;
  position: relative;
  text-align: center;
  z-index: 2; }

.title__span {
  display: block;
  font-size: 22px;
  letter-spacing: -0.66px;
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.24);
  text-transform: uppercase; }
  .title__span span {
    color: #dce8ff; }

.line {
  background: #fff;
  border: none;
  color: #fff;
  height: 1px;
  margin: 13px auto 19px;
  max-width: 535px; }

.fume {
  background: url(../img/fume.png) no-repeat center top;
  background-size: contain;
  bottom: -40px;
  height: 490px;
  left: 0;
  position: absolute;
  right: 0;
  -webkit-transform: translateX(-100px);
  transform: translateX(-100px);
  z-index: 1; }

.anim3 {
  animation: fume 6s infinite linear; }

.anim4 {
  animation: fume2 8s infinite linear;
  left: -800px; }

@keyframes fume {
  from {
    transform: translateX(-100px); }
  to {
    transform: translateX(100%); } }

@keyframes fume2 {
  from {
    transform: translateX(-800px); }
  to {
    transform: translateX(100%); } }

.statistic {
  display: flex;
  justify-content: space-between;
  margin: 209px 0 0 0; }
  .statistic li {
    align-items: flex-end;
    color: #fff;
    display: flex;
    position: relative; }
  .statistic .count {
    color: #aebdda;
    font: 60px 'bebas_neuebold'; }
  .statistic .statistic__descript {
    margin-left: 9px;
    padding: 0 0 10px 0; }
  .statistic .row_1 {
    display: block;
    font-size: 28px;
    letter-spacing: -0.84px; }
  .statistic .row_2 {
    font-size: 16px;
    letter-spacing: -0.84px; }

/*========================== sect__2 ==========================*/
.sect__2 {
  background: url(../img/gray_bg.png) no-repeat left bottom, url(../img/pattern.jpg) repeat;
  padding: 80px 0 102px 0; }
  .sect__2 .h2 {
    margin: 0 0 40px 0; }

.advantages__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 15px 0; }
  .advantages__list li {
    position: relative; }
    .advantages__list li img {
      display: block;
      width: 100%; }
  .advantages__list .advantages__box {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 1; }
  .advantages__list .advantages__title {
    background: #c70505;
    color: #fff;
    display: block;
    font-size: 16px;
    letter-spacing: -0.48px;
    padding: 10px 5px;
    text-align: center;
    text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.36);
    text-transform: uppercase; }
  .advantages__list .advantages__descript {
    color: #fff;
    letter-spacing: -0.42px;
    padding: 10px;
    text-align: center;
    text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.36); }

.link_1 {
  padding: 0 30px 0 0;
  text-align: right; }
  .link_1 a {
    text-decoration: underline; }

/*========================== sect__3 ==========================*/
.sect__3 {
  background-image: url(../img/sect__3.jpg);
  padding: 75px 0 60px 0;
  position: relative; }
  .sect__3::after {
    background: url(../img/img.png);
    bottom: -233px;
    content: '';
    height: 364px;
    position: absolute;
    right: 10px;
    width: 366px;
    z-index: 1; }

.title__box {
  border-bottom: 1px solid #ffffff;
  display: table;
  margin: 0 auto;
  padding: 0 30px 6px 30px;
  text-align: center;
  text-transform: uppercase; }
  .title__box span {
    color: #dce8ff;
    display: block;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.66px;
    text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.24); }
  .title__box .h2 {
    color: #fff;
    text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.24); }

.myslider {
  margin: 45px auto 0;
  width: 600px; }

.wrap__img {
  position: relative; }

.myslider__img {
  border: 1px solid rgba(255, 255, 255, 0.67); }

.myslider__descript {
  background-image: linear-gradient(-210deg, #304b7d 0%, #365895 100%);
  border: 1px solid rgba(255, 255, 255, 0.57);
  bottom: -21px;
  color: #ffffff;
  display: block;
  font-size: 16px;
  left: calc(50% - 120px);
  line-height: 28px;
  padding: 5px;
  position: absolute;
  text-align: center;
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.36);
  text-transform: uppercase;
  width: 240px;
  z-index: 1; }

.myslider__txt {
  color: #ffffff;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.42px;
  margin: 30px 0 0 0;
  text-align: center;
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.55); }

/*========================== sect__4 ==========================*/
.sect__4 {
  background: url(../img/pattern.jpg) repeat;
  padding: 85px 0 118px 0; }
  .sect__4 .h2 {
    margin: 0 auto 37px;
    text-align: center; }

.avtopark {
  display: flex;
  justify-content: space-between; }
  .avtopark li {
    width: 462px; }
  .avtopark .wrap__img {
    align-items: flex-end;
    display: flex;
    min-height: 202px; }

.avtopark__info {
  align-items: center;
  background-image: linear-gradient(-210deg, #304b7d 0%, #365895 100%);
  border: 1px solid rgba(255, 255, 255, 0.57);
  color: #fff;
  display: flex; }
  .avtopark__info > * {
    padding: 10px 5px;
    text-align: center; }
  .avtopark__info--name {
    font-size: 16px;
    text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.36);
    text-transform: uppercase;
    width: 185px; }
  .avtopark__info--count {
    background: #2d3c5b;
    font-size: 16px;
    width: 57px; }
  .avtopark__info--data {
    font-weight: 400;
    letter-spacing: -0.42px;
    width: 220px; }

.avtopark__descript {
  color: #2b303c;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.42px;
  padding: 10px 5px 17px;
  text-align: center; }

/*========================== sect_5 ==========================*/
.sect__5 {
  background-image: url(../img/sect_5__bg.jpg);
  padding: 90px 0 0 0; }
  .sect__5 .title__box {
    margin-bottom: 40px;
    margin-left: 0;
    padding-left: 0;
    text-align: left; }
    .sect__5 .title__box .h2 {
      text-align: left; }

.myform {
  width: 320px; }
  .myform input {
    margin: 0 0 6px 0; }
  .myform .red__btn {
    width: 320px; }

.priv {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.36px;
  margin: 15px 0 0 0;
  text-align: center; }
  .priv a {
    color: #b7c3db;
    text-decoration: underline; }

/*========================== footer ==========================*/
.footer {
  background: rgba(0, 0, 0, 0.3);
  margin: 40px 0 0 0; }
  .footer .center {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .footer .center .order__btn {
      color: #fff; }

.footer__download {
  color: #c8d5ef;
  letter-spacing: -0.42px;
  text-decoration: underline; }
  .footer__download:hover {
    text-decoration: none; }

/*========================== popup ==========================*/
.overlay {
  background: url(../img/overlay-bg.png) repeat-y;
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 995; }

.close__popup {
  align-items: center;
  background: #b90505;
  border: 3px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.75), inset 0 0 32px rgba(0, 0, 0, 0.21);
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 22px;
  font-weight: 700;
  height: 56px;
  justify-content: center;
  letter-spacing: -0.66px;
  position: absolute;
  right: 12px;
  top: 10px;
  text-transform: uppercase;
  width: 56px; }

.popup {
  background: #fff;
  border-radius: 15px;
  display: none;
  left: 50%;
  overflow: hidden;
  padding: 50px 300px 35px 50px;
  position: fixed;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 740px;
  z-index: 997; }
  .popup .h3 {
    color: #5e77a6;
    margin: 0 0 30px 0;
    text-align: center; }
  .popup .priv {
    color: #282828;
    max-width: 300px; }
    .popup .priv a {
      color: #3a5079; }
  .popup .red__btn {
    padding-right: 0; }

.popup__title {
  color: #5f7196;
  display: block;
  font-size: 32px;
  line-height: 1.1;
  margin: 0 0 25px 0;
  text-align: center; }

.popup__form {
  margin: 0 0 0 17px; }
  .popup__form input {
    border: 1px solid rgba(0, 0, 0, 0.17);
    margin: 0 0 6px 0; }
  .popup__form .red__btn {
    margin: 0;
    width: 320px; }

#ordercall {
  background: url(../img/ordercall-bg.jpg) no-repeat center top;
  background-size: cover; }

#orderbeton {
  background: url(../img/orderbeton-bg.jpg) no-repeat center top;
  background-size: cover; }
  #orderbeton .h3 {
    max-width: 320px;
    margin-left: 17px; }

#arendabeton {
  background: url(../img/arendabeton-bg.jpg) no-repeat center top;
  background-size: cover; }
  #arendabeton .h3 {
    color: #fff; }

#sertif {
  background: url(../img/sertif-bg.jpg) no-repeat center top;
  background-size: cover; }
  #sertif .h3 {
    margin-left: 17px;
    max-width: 320px; }

/*========================== slick  ==========================*/
.slick-arrow {
  border-radius: 50%;
  border: 2px solid #ffffff;
  height: 68px;
  overflow: hidden;
  position: absolute;
  top: 114px;
  text-indent: 100px;
  width: 68px;
  z-index: 1; }

.slick-prev {
  background: url(../img/arrow-prev.png) no-repeat center, linear-gradient(to top, #820b0b 0%, #c91010 100%);
  left: -104px; }
  .slick-prev:hover {
    background: url(../img/arrow-prev.png) no-repeat center, linear-gradient(to bottom, #820b0b 0%, #c91010 100%); }

.slick-next {
  background: url(../img/arrow-next.png) no-repeat center, linear-gradient(to top, #820b0b 0%, #c91010 100%);
  right: -104px; }
  .slick-next:hover {
    background: url(../img/arrow-next.png) no-repeat center, linear-gradient(to bottom, #820b0b 0%, #c91010 100%); }

/*========================== mobile_menu ==========================*/
.toggle-mnu {
  height: 24px;
  display: none;
  width: 28px; }

.toggle-mnu span:after, .toggle-mnu span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 9px; }

.toggle-mnu span:after {
  top: 18px; }

.toggle-mnu span {
  position: relative;
  display: block; }

.toggle-mnu span, .toggle-mnu span:after, .toggle-mnu span:before {
  width: 100%;
  height: 2px;
  background-color: #fff;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  backface-visibility: hidden;
  border-radius: 2px; }

.toggle-mnu.on span {
  background-color: transparent; }

.toggle-mnu.on span:before {
  -webkit-transform: rotate(45deg) translate(-1px, 0px);
  -ms-transform: rotate(45deg) translate(-1px, 0px);
  -o-transform: rotate(45deg) translate(-1px, 0px);
  transform: rotate(45deg) translate(-1px, 0px); }

.toggle-mnu.on span:after {
  -webkit-transform: rotate(-45deg) translate(6px, -7px);
  -ms-transform: rotate(-45deg) translate(6px, -7px);
  -o-transform: rotate(-45deg) translate(6px, -7px);
  transform: rotate(-45deg) translate(6px, -7px); }

/*========================== single ==========================*/
.blue__btn {
  background-image: linear-gradient(to top, #5e77a6 0%, #7798d5 100%);
  color: #fff;
  display: block;
  font-weight: 700;
  height: 44px;
  letter-spacing: -0.42px;
  text-align: center;
  text-transform: uppercase;
  width: 201px; }
  .blue__btn:hover {
    background-image: linear-gradient(to top, #ad0d0d 0%, #cb1111 100%); }

.single__page {
  background: #161b29 url(../img/single-bg.jpg) no-repeat center top;
  padding-bottom: 0;
  padding-top: 100px; }
  .single__page .center {
    width: 1038px; }

.price {
  background: #fff url(../img/price-bg.png) no-repeat center top;
  border-radius: 12px;
  padding: 35px 50px 113px 44px; }
  .price .h2 {
    color: #617bac;
    margin: 0 0 45px 0; }

.tabs {
  display: flex; }
  .tabs li {
    background-image: linear-gradient(to top, #5e77a6 0%, #7798d5 100%);
    border-radius: 10px 10px 0 0;
    overflow: hidden; }
    .tabs li + li {
      margin-left: 1px; }
    .tabs li:hover, .tabs li.active {
      background-image: linear-gradient(to top, #ad0d0d 0%, #cb1111 100%); }
  .tabs a {
    color: #fff;
    display: block;
    letter-spacing: -0.42px;
    padding: 15px 30px;
    text-transform: uppercase; }

.wrap__tab {
  margin: 0 0 30px 0; }

.table {
  border: 1px solid rgba(0, 0, 0, 0.39);
  text-transform: uppercase;
  width: calc(100% - 2px); }
  .table td, .table th {
    border-bottom: 1px solid rgba(92, 95, 102, 0.3);
    color: #6783b7;
    text-align: center; }
    .table td:nth-child(1), .table th:nth-child(1) {
      background: #e5eeff;
      width: 221px; }
    .table td:nth-child(2), .table th:nth-child(2) {
      background: #fff;
      width: 255px; }
    .table td:nth-child(3), .table th:nth-child(3) {
      background: #e5eeff;
      width: 268px; }
    .table td:nth-child(4), .table th:nth-child(4) {
      background: #fff; }
  .table th {
    font-size: 18px;
    padding-bottom: 15px;
    padding-top: 15px; }

.descript__1 {
  color: #2b303c;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.48px; }

.more__txt {
  display: none;
  padding: 20px 0; }
  .more__txt p:not(:nth-last-of-type(1)) {
    margin-bottom: 10px; }

.more__btn {
  background: #3d5c9b;
  color: #d2ddff;
  display: block;
  font-weight: 700;
  height: 42px;
  letter-spacing: -0.42px;
  margin: 19px 0 0 0;
  text-align: center;
  text-transform: uppercase;
  width: 100%; }
  .more__btn img {
    display: inline-block;
    margin: 0 0 0 20px;
    vertical-align: middle; }

.delivery__page {
  background: url(../img/delivery-bg.png) no-repeat right top, #ffffff url(../img/line-bg.jpg) no-repeat right bottom;
  overflow: hidden; }

.delivery__descript {
  margin: 0 0 15px 0; }
  .delivery__descript strong {
    color: #5e77a6; }

.delivery__mixer {
  margin: 0 30px 0 0; }

.delivery__title {
  clear: both;
  color: #5e77a6;
  display: block;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.48px;
  padding: 20px 0 0 0;
  text-align: center; }

.table2 {
  margin: 15px 0 0 0;
  width: 100%; }
  .table2 tr:nth-child(1):nth-child(1) {
    background: #6c7cac; }
  .table2 tr:nth-child(1):nth-child(2) {
    background: #6676a9; }
  .table2 tr:nth-child(1):nth-child(3) {
    background: #6c7cac; }
  .table2 tr:nth-child(2):nth-child(1) {
    background: #6374a7; }
  .table2 tr:nth-child(2):nth-child(2) {
    background: #5f70a5; }
  .table2 tr:nth-child(2):nth-child(3) {
    background: #6374a7; }
  .table2 td {
    color: #fff;
    font-weight: 400;
    letter-spacing: -0.42px;
    padding: 15px 5px;
    text-align: center;
    text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.47); }

.deliver__footer {
  align-items: center;
  display: flex;
  margin: 54px 0 0 0; }
  .deliver__footer .red__btn {
    margin-left: 43px; }

.span1 {
  color: #5f70a5;
  font-size: 16px;
  letter-spacing: -0.48px;
  text-decoration: underline; }

@media (max-width: 1340px) {
  .sect__3::after {
    right: -170px; } }

@media (max-width: 1070px) {
  .single__page .header .center {
    padding-left: 15px;
    padding-right: 15px; }
  .single__page .center {
    width: 100%; } }

@media (max-width: 970px) {
  .center {
    padding-left: 15px;
    padding-right: 15px;
    width: 800px; }
  .sect__3::after {
    display: none; }
  .toggle-mnu {
    display: block; }
  .avtopark {
    display: block; }
    .avtopark li {
      margin-left: auto;
      margin-right: auto; }
  .advantages__list li {
    margin-bottom: 10px;
    max-height: 240px;
    overflow: hidden;
    width: 45%; }
    .advantages__list li img {
      object-fit: cover; }
  .menu {
    background: url(../img/sect_1__bg.jpg) no-repeat center top;
    background-size: cover;
    bottom: 0;
    -ms-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    transition: transform .3s ease;
    transform: translateX(-100%);
    width: 70%;
    z-index: 90; }
    .menu li + li {
      margin-left: 0; }
    .menu a {
      color: #fff;
      font-size: 20px;
      padding: 10px 0; }
    .menu .special {
      height: 30px;
      font-size: 14px;
      line-height: 10px;
      width: 30px; }
  .menu__active {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@media (max-width: 830px) {
  .center {
    width: 700px; }
  .slick-prev {
    left: -35px; }
  .slick-next {
    right: -35px; }
  .delivery__page {
    background: #fff url(../img/delivery-bg.png) no-repeat right -400px top; } }

@media (max-width: 785px) {
  .popup {
    padding-right: 0;
    width: 95%; }
    .popup .h3 {
      max-width: 320px; } }

@media (max-width: 730px) {
  .center {
    width: 600px; }
  .sect__2, .sect__4 {
    padding-bottom: 50px;
    padding-top: 50px; }
  .statistic {
    flex-wrap: wrap;
    margin-top: 100px; }
  .myslider {
    width: 100%; }
  .slick-prev {
    left: -5px; }
  .slick-next {
    right: -5px; } }

@media (max-width: 680px) {
  .tabs a {
    padding-left: 15px;
    padding-right: 15px; } }

@media (max-width: 630px) {
  h1, .h1, h2, .h2 {
    font-size: 50px; }
  .center {
    width: 500px; }
  .phone {
    font-size: 27px; }
  .footer .phone {
    font-size: 22px; }
  .footer .order__btn {
    font-size: 15px; }
  .footer .contacts {
    padding: 20px 0;
    text-align: center;
    width: 100%; }
  .table th {
    font-size: 17px; }
  .table th:nth-child(1), .table td:nth-child(1) {
    width: 125px; }
  .table .blue__btn {
    width: 150px; }
  .delivery__mixer {
    display: block;
    float: none; }
  .deliver__footer {
    display: block; }
    .deliver__footer .span1 {
      display: block;
      margin: 0 0 20px 0;
      text-align: center; }
    .deliver__footer .red__btn {
      margin-left: auto; }
  .price {
    background-position: left top; } }

@media (max-width: 530px) {
  .center {
    width: 100%; }
  .avtopark li {
    width: 100%; }
  .delivery__page {
    background-position: right -500px top; } }

@media (max-width: 500px) {
  h3, .h3 {
    font-size: 30px; }
  .advantages__list {
    display: block; }
    .advantages__list li {
      margin-left: auto;
      margin-right: auto;
      max-height: 100%;
      max-width: 320px;
      width: 100%; }
  .popup {
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 70px; }
    .popup input, .popup .red__btn, .popup .priv, .popup .h3 {
      margin-left: auto;
      margin-right: auto; }
  .popup__form {
    margin-left: 0; }
  #orderbeton .h3 {
    margin-left: 0;
    max-width: 100%; } }

@media (max-width: 465px) {
  .phone {
    font-size: 24px; }
  .statistic li {
    width: 100%; }
  .popup input, .popup .red__btn {
    width: 100%; }
  .tabs {
    display: block; }
    .tabs li {
      margin-left: 0; } }

@media (max-width: 420px) {
  h1, .h1, h2, .h2 {
    font-size: 44px; }
  .table th {
    font-size: 14px; }
  .table th:nth-child(1), .table td:nth-child(1), .table th:nth-child(2), .table td:nth-child(2) {
    padding: 0 11px;
    width: auto; }
  .table .blue__btn {
    width: 60px; }
  .table .blue__btn span {
    display: none; } }

@media (max-width: 375px) {
  .header .contacts {
    display: none; }
  .myform input, .myform .red__btn, .myform {
    width: 100%; }
  .avtopark__info--name {
    font-size: 12px; }
  .delivery__page {
    background-position: right -550px top; }
  .avtopark .wrap__img {
    min-height: auto; }
  .avtopark li + li {
    margin-top: 20px; }
  .footer__download {
    line-height: 1.2;
    text-align: center;
    width: 60%; } }
