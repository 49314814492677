@charset "UTF-8";

/**
 * Основные стили
 **/

body {
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 700;
	overflow-x: hidden;
}

strong {
	font-weight: 700;
}

a {
	color: $blue;
	text-decoration: none;
	transition: color .3s ease;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
	font-family: 'bebas_neuebold';
	font-weight: normal;
	text-transform: uppercase;
}

h1, .h1, h2, .h2 {
	font-size: 60px;
}

h2, .h2 {
	color: #242c3a;
	letter-spacing: -1.8px;
	
	span {
		color: #5078a9;
	}
}

h3, .h3 {
	font-size: 40px;
}

.h__line {
	display: table;
	position: relative;
	
	::after {
		background: #aebdda;
		content: '';
		display: block;
		height: 1px;
		margin: 10px 0 0 0;
	}
}

.left {
	float: left;
}

.right {
	float: right;
}

section {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center top;
}

input[type=text], input[type=email] {
	background: #eff4fe;
	color: #30436e;
	display: block;
	font: 400 16px Roboto;
	height: 50px;
	letter-spacing: -0.48px;
	text-align: center;
	width: 320px;
}

.red__btn {
	background-image: linear-gradient(to top, #890b0b 0%, #d20f0f 100%);
	color: #fff;
	display: block;
	font-size: 16px;
	height: 50px;
	letter-spacing: -0.48px;
	margin-left: auto;
	margin-right: auto;
	padding: 0 50px 0 0;
	position: relative;
	width: 264px;
	z-index: 2;
	
	span {
		background: #690101 url(../img/car-icon.png) no-repeat center;
		height: 50px;
		position: absolute;
		right: 0;
		top: 0;
		width: 50px;
	}
	
	&:hover {
		background-image: linear-gradient(to bottom, #890b0b 0%, #d20f0f 100%);
	}
	
	&--beton {
		
		span {
			background-image: url(../img/betoncar-icon.png);
		}
	}
}

.wrapper {
	margin: 0 auto;
	max-width: 2560px;
	overflow: hidden;
}

.center {
	margin: 0 auto;
	width: 940px;
}

.sect__1 {
	background-image: url(../img/sect_1__bg.jpg);
	padding: 249px 0 30px 0;
	position: relative;
}

.header {
	background: rgba(0, 0, 0, .3);
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 5;
	
	&.fixed {
		background: url(../img/sect_1__bg.jpg) no-repeat top center;
	}
	
	.center {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}
	
	a {color: #fff;}
}

.logo {
	background: #d70808 url(../img/car-icon.png) no-repeat right 8px top 10px;
	color: #fff;
	display: block;
	font-size: 24px;
	padding: 10px 0 0 0;
	width: 107px;
	
	.logo__row1 {
		display: block;
		padding: 0 0 0 9px;
	}
	
	.logo__row2 {
		background: #bd0707;
		color: #ffcfcf;
		display: block;
		font-size: 12px;
		line-height: 14px;
		letter-spacing: -0.36px;
		margin: 5px 0 0 0;
		padding: 5px 5px 5px 8px;
	}
}

.menu {
	display: flex;
	
	li {
		letter-spacing: -0.42px;
		
		+ li {
			margin-left: 35px;
		}
	}
	
	a {
		color: #fff;
		display: block;
		
		&:hover {
			color: #aebdda;
		}
	}
}

.contacts {
	text-align: right;
	
	.order__btn {
		display: inline-block;
		margin: 2px 0 0 0;
		text-decoration: underline;
	}
}

.phone {
	color: #fff;
	display: block;
	font-size: 30px;
	letter-spacing: -0.9px;
	
	span {
		color: #aebdda;
	}
}

.title__1 {
	color: #fff;
	position: relative;
	text-align: center;
	z-index: 2;
}

.title__span {
	display: block;
	font-size: 22px;
	letter-spacing: -0.66px;
	text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.24);
	text-transform: uppercase;
	
	span {
		color: #dce8ff;	
	}
}

.line {
	@include hr(#fff);
	margin: 13px auto 19px;
	max-width: 535px;
}

.fume {
	background: url(../img/fume.png) no-repeat center top;
	background-size: contain;
	bottom: -40px;
	height: 490px;
	left: 0;
	position: absolute;
	right: 0;
	-webkit-transform: translateX(-100px);
	transform: translateX(-100px);
	z-index: 1; }

.anim3 {
	animation: fume 6s infinite linear; }

.anim4 {
	animation: fume2 8s infinite linear;
	left: -800px; }

@keyframes fume {
	from {
		transform: translateX(-100px); }
	to {
		transform: translateX(100%); } }

@keyframes fume2 {
	from {
		transform: translateX(-800px); }
	to {
		transform: translateX(100%); } }

.statistic {
	display: flex;
	justify-content: space-between;
	margin: 209px 0 0 0;
	
	li {
		align-items: flex-end;
		color: #fff;
		display: flex;
		position: relative;
	}
	
	.count {
		color: #aebdda;
		font: 60px 'bebas_neuebold';
	}
	
	.statistic__descript {
		margin-left: 9px;
		padding: 0 0 10px 0;
	}
	
	.row_1 {
		display: block;
		font-size: 28px;
		letter-spacing: -0.84px;
	}
	
	.row_2 {
		font-size: 16px;
		letter-spacing: -0.84px;
	}
}

/*========================== sect__2 ==========================*/

.sect__2 {
	background: url(../img/gray_bg.png) no-repeat left bottom, url(../img/pattern.jpg) repeat;
	padding: 80px 0 102px 0;
	
	.h2 {
		margin: 0 0 40px 0;
	}
}

.advantages__list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0 0 15px 0;
	
	li {
		position: relative;
		
		img {
			display: block;
			width: 100%;
		}
	}
	
	.advantages__box {
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		z-index: 1;
	}
	
	.advantages__title {
		background: $red;
		color: #fff;
		display: block;
		font-size: 16px;
		letter-spacing: -0.48px;
		padding: 10px 5px;
		text-align: center;
		text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.36);
		text-transform: uppercase;
	}
	
	.advantages__descript {
		color: #fff;
		letter-spacing: -0.42px;
		padding: 10px;
		text-align: center;
		text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.36);
	}
}

.link_1 {
	padding: 0 30px 0 0;
	text-align: right;
	
	a {
		text-decoration: underline;
	}
}

/*========================== sect__3 ==========================*/

.sect__3 {
	background-image: url(../img/sect__3.jpg);
	padding: 75px 0 60px 0;
	position: relative;
	
	&::after {
		background: url(../img/img.png);
		bottom: -233px;
		content: '';
		height: 364px;
		position: absolute;
		right: 10px;
		width: 366px;
		z-index: 1;
	}
}

.title__box {
	border-bottom: 1px solid #ffffff;
	display: table;
	margin: 0 auto;
	padding: 0 30px 6px 30px;
	text-align: center;
	text-transform: uppercase;
	
	span {
		color: #dce8ff;
		display: block;
		font-size: 22px;
		line-height: 28px;
		letter-spacing: -0.66px;
		text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.24);
	}
	
	.h2 {
		color: #fff;
		text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.24);
	}
}

.myslider  {
	margin: 45px auto 0;
	width: 600px;
}

.wrap__img {
	position: relative;
}

.myslider__img {
	border: 1px solid rgba(255, 255, 255, 0.67);
}

.myslider__descript {
	background-image: linear-gradient(-210deg, #304b7d 0%, #365895 100%);
	border: 1px solid rgba(255, 255, 255, 0.57);
	bottom: -21px;
	color: #ffffff;
	display: block;
	font-size: 16px;
	left: calc(50% - 120px);
	line-height: 28px;
	padding: 5px;
	position: absolute;
	text-align: center;
	text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.36);
	text-transform: uppercase;
	width: 240px;
	z-index: 1;
}

.myslider__txt {
	color: #ffffff;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: -0.42px;
	margin: 30px 0 0 0;
	text-align: center;
	text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.55);
}

/*========================== sect__4 ==========================*/

.sect__4 {
	background: url(../img/pattern.jpg) repeat;
	padding: 85px 0 118px 0;
	
	.h2 {
		margin: 0 auto 37px;
		text-align: center;
	}
}

.avtopark {
	display: flex;
	justify-content: space-between;
	
	li {
		width: 462px;
	}
	
	.wrap__img {
		align-items: flex-end;
		display: flex;
		min-height: 202px;
	}
}

.avtopark__info {
	align-items: center;
	background-image: linear-gradient(-210deg, #304b7d 0%, #365895 100%);
	border: 1px solid rgba(255, 255, 255, 0.57);
	color: #fff;
	display: flex;
	
	> * {
		padding: 10px 5px;
		text-align: center;
	}
	
	&--name {
		font-size: 16px;
		text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.36);
		text-transform: uppercase;
		width: 185px;
	}
	
	&--count {
		background: #2d3c5b;
		font-size: 16px;
		width: 57px;
	}
	
	&--data {
		font-weight: 400;
		letter-spacing: -0.42px;
		width: 220px;
	}
}

.avtopark__descript {
	color: #2b303c;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: -0.42px;
	padding: 10px 5px 17px;
	text-align: center;
}

/*========================== sect_5 ==========================*/

.sect__5 {
	background-image: url(../img/sect_5__bg.jpg);
	padding: 90px 0 0 0;
	
	.title__box {
		margin-bottom: 40px;
		margin-left: 0;
		padding-left: 0;
		text-align: left;
		
		.h2 {
			text-align: left;
		}
	}
}

.myform {
	width: 320px;
	
	input {
		margin: 0 0 6px 0;
	}
	
	.red__btn {
		width: 320px;		
	}
}

.priv {
	color: #fff;
	font-size: 12px;
	font-weight: 400;
	line-height: 14px;
	letter-spacing: -0.36px;
	margin: 15px 0 0 0;
	text-align: center;
	
	a {
		color: #b7c3db;
		text-decoration: underline;
	}
}

/*========================== footer ==========================*/

.footer {
	background: rgba(0, 0, 0, .3);
	margin: 40px 0 0 0;

	.center {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		
		.order__btn {
			color: #fff;
		}
	}
}

.footer__download {
	color: #c8d5ef;
	letter-spacing: -0.42px;
	text-decoration: underline;
	
	&:hover {
		text-decoration: none;
	}
}

/*========================== popup ==========================*/

.overlay {
	background: url(../img/overlay-bg.png) repeat-y;
	bottom: 0;
	display: none;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 995;
}

.close__popup {
	align-items: center;
	background: #b90505;
	border: 3px solid #fff;
	border-radius: 50%;
	box-shadow: 0 0 18px rgba(0, 0, 0, 0.75), inset 0 0 32px rgba(0, 0, 0, 0.21);
	color: #fff;
	cursor: pointer;
	display: flex;
	font-size: 22px;
	font-weight: 700;
	height: 56px;
	justify-content: center;
	letter-spacing: -0.66px;
	position: absolute;
	right: 12px;
	top: 10px;
	text-transform: uppercase;
	width: 56px;
}

.popup {
	background: #fff;
	border-radius: 15px;
	display: none;
	left: 50%;
	overflow: hidden;
	padding: 50px 300px 35px 50px;
	position: fixed;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 740px;
	z-index: 997;
	
	.h3 {
		color: #5e77a6;
		margin: 0 0 30px 0;
		text-align: center;
	}
	
	.priv {
		color: #282828;
		max-width: 300px;
		
		a {
			color: #3a5079;
		}
	}
	
	.red__btn {
		padding-right: 0;
	}
}

.popup__title {
	color: #5f7196;
	display: block;
	font-size: 32px;
	line-height: 1.1;
	margin: 0 0 25px 0;
	text-align: center;
}

.popup__form {
	margin: 0 0 0 17px;
	
	input {
		border: 1px solid rgba(0, 0, 0, 0.17);
		margin: 0 0 6px 0;
	}
	.red__btn {
		margin: 0;
		width: 320px;
	}
}

#ordercall {
	background: url(../img/ordercall-bg.jpg) no-repeat center top;
	background-size: cover;
}

#orderbeton {
	background: url(../img/orderbeton-bg.jpg) no-repeat center top;
	background-size: cover;
	
	.h3 {
		max-width: 320px;	
		margin-left: 17px;
	}
}

#arendabeton {
	background: url(../img/arendabeton-bg.jpg) no-repeat center top;
	background-size: cover;
	
	.h3 {
		color: #fff;
	}
}

#sertif {
	background: url(../img/sertif-bg.jpg) no-repeat center top;
	background-size: cover;
	
	.h3 {
		margin-left: 17px;
		max-width: 320px;
	}
}
