/*========================== single ==========================*/

.blue__btn {
	background-image: linear-gradient(to top, #5e77a6 0%, #7798d5 100%);
	color: #fff;
	display: block;
	font-weight: 700;
	height: 44px;
	letter-spacing: -0.42px;
	text-align: center;
	text-transform: uppercase;
	width: 201px;
	
	&:hover {
		background-image: linear-gradient(to top, #ad0d0d 0%, #cb1111 100%);
	}
}

.single__page {
	background: #161b29 url(../img/single-bg.jpg) no-repeat center top;
	padding-bottom: 0;
	padding-top: 100px;
	
	.center {
		width: 1038px;
	}
}

.price {
	background: #fff url(../img/price-bg.png) no-repeat center top;
	border-radius: 12px;
	padding: 35px 50px 113px 44px;
	
	.h2 {
		color: #617bac;
		margin: 0 0 45px 0;
	}
}

.tabs {
	display: flex;
	
	li {
		background-image: linear-gradient(to top, #5e77a6 0%, #7798d5 100%);
		border-radius: 10px 10px 0 0;
		overflow: hidden;
		
		+ li {
			margin-left: 1px;
		}
		
		&:hover, &.active  {
			background-image: linear-gradient(to top, #ad0d0d 0%, #cb1111 100%);
		}
	}
	
	a {
		color: #fff;
		display: block;
		letter-spacing: -0.42px;
		padding: 15px 30px;
		text-transform: uppercase;
	}
}

.wrap__tab {
	margin: 0 0 30px 0;
}

.table {
	border: 1px solid rgba(0, 0, 0, 0.39);
	text-transform: uppercase;
	width: calc(100% - 2px);
	
	td, th {
		border-bottom: 1px solid rgba(92, 95, 102, .3);
		color: #6783b7;
		text-align: center;
		
		&:nth-child(1) {
			background: #e5eeff;
			width: 221px;
		}
		&:nth-child(2) {
			background: #fff;
			width: 255px;
		}
		&:nth-child(3) {
			background: #e5eeff;
			width: 268px;
		}
		&:nth-child(4) {
			background: #fff;
		}
	}
	
	th {
		font-size: 18px;
		padding-bottom: 15px;
		padding-top: 15px;
	}

}

.descript__1 {
	color: #2b303c;
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: -0.48px;
}

.more__txt {
	display: none;
	padding: 20px 0;
	
	p:not(:nth-last-of-type(1)) {
		margin-bottom: 10px;
	}
}

.more__btn {
	background: #3d5c9b;
	color: #d2ddff;
	display: block;
	font-weight: 700;
	height: 42px;
	letter-spacing: -0.42px;
	margin: 19px 0 0 0;
	text-align: center;
	text-transform: uppercase;
	width: 100%;
	
	img {
		display: inline-block;
		margin: 0 0 0 20px;
		vertical-align: middle;
	}
}

.delivery__page {
	background: url(../img/delivery-bg.png) no-repeat right top, #ffffff url(../img/line-bg.jpg) no-repeat right bottom;
	overflow: hidden;
}

.delivery__descript {
	margin: 0 0 15px 0;
	
	strong {
		color: #5e77a6;
	}
}

.delivery__mixer {
	margin: 0 30px 0 0;
}

.delivery__title {
	clear: both;
	color: #5e77a6;
	display: block;
	font-size: 16px;
	line-height: 18px;
	letter-spacing: -0.48px;
	padding: 20px 0 0 0;
	text-align: center;
}

.table2 {
	margin: 15px 0 0 0;
	width: 100%;
	
	tr:nth-child(1) {
		&:nth-child(1) {
			background: #6c7cac;	
		}
		&:nth-child(2) {
			background: #6676a9;	
		}
		&:nth-child(3) {
			background: #6c7cac;	
		}
	}
	
	tr:nth-child(2) {
		&:nth-child(1) {
			background: #6374a7;	
		}
		&:nth-child(2) {
			background: #5f70a5;	
		}
		&:nth-child(3) {
			background: #6374a7;	
		}
	}
	
	td {
		color: #fff;
		font-weight: 400;
		letter-spacing: -0.42px;
		padding: 15px 5px;
		text-align: center;
		text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.47);
	}
}

.deliver__footer {
	align-items: center;
	display: flex;
	margin: 54px 0 0 0;
	
	.red__btn {
		margin-left: 43px;
	}
}

.span1 {
	color: #5f70a5;
	font-size: 16px;
	letter-spacing: -0.48px;
	text-decoration: underline;
}