@font-face {
	font-family: 'bebas_neuebold';
	src: url('../fonts/BebasNeueBold/bebasneue_bold-webfont.eot');
	src: url('../fonts/BebasNeueBold/bebasneue_bold-webfont.eot?#iefix') format('embedded-opentype'),
		url('../fonts/BebasNeueBold/bebasneue_bold-webfont.woff2') format('woff2'),
		url('../fonts/BebasNeueBold/bebasneue_bold-webfont.woff') format('woff'),
		url('../fonts/BebasNeueBold/bebasneue_bold-webfont.ttf') format('truetype'),
		url('../fonts/BebasNeueBold/bebasneue_bold-webfont.svg#bebas_neuebold') format('svg');
	font-weight: normal;
	font-style: normal;
}