/*========================== slick  ==========================*/

.slick-arrow {
	border-radius: 50%;
	border: 2px solid #ffffff;
	height: 68px;
	overflow: hidden;
	position: absolute;
	top: 114px;
	text-indent: 100px;
	width: 68px;
	z-index: 1;
}

.slick-prev {
	background: url(../img/arrow-prev.png) no-repeat center, linear-gradient(to top, #820b0b 0%, #c91010 100%);
	left: -104px;
	
	&:hover {
		background: url(../img/arrow-prev.png) no-repeat center, linear-gradient(to bottom, #820b0b 0%, #c91010 100%);
	}
}

.slick-next {
	background: url(../img/arrow-next.png) no-repeat center, linear-gradient(to top, #820b0b 0%, #c91010 100%);
	right: -104px;
	
	&:hover {
		background: url(../img/arrow-next.png) no-repeat center, linear-gradient(to bottom, #820b0b 0%, #c91010 100%);
	}
}